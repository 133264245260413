// import "typeface-hk-grotesk"
// import "@fontsource/poppins"
import "typeface-poppins"
import "@fontsource/work-sans"
import "@fontsource/work-sans/300.css"

import "./src/styles/global.scss"

function anchorScroll(location) {
  // Check for location so build does not fail
  if (location && location.hash) {
    setTimeout(() => {
      // document.querySelector(${location.hash}).scrollIntoView({ behavior: 'smooth', block: 'start' });
      console.log("location hash: ", location.hash)
      const item = document.querySelector(`${location.hash}`).offsetTop
      // const mainNavHeight = document.querySelector(nav).offsetHeight
      window.scrollTo({
        // top: item - mainNavHeight,
        top: item,
        left: 0,
        behavior: "smooth",
      })
    }, 0)
  }
}

export function onRouteUpdate({ location }) {
  console.log("...updating route")
  anchorScroll(location)
  return true
}

export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  anchorScroll(location)
  return true
}
